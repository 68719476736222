import styled from 'styled-components';

export const Content = styled.div`
  height: 100%;
  min-height: inherit;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  max-width: 1200px;
  padding-inline: 4rem;
  padding-top: 100px;
  padding-bottom: 50px;
  margin-inline: auto;

  @media (max-width: 767px) {
    padding-inline: 2rem;
  }
`;
