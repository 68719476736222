import styled from 'styled-components';

export const Container = styled.div`
  .section-idle {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;

    .gradient-layer {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  img {
    border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 100%;
    object-fit: cover;
  }
`;

export const styles = {
  title: {
    fontStyle: 'normal',
    fontSize: ['38px', '40px', '50px', '60px', '65px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    lineHeight: '1.25',
    textAlign: 'left',
  },
  description: {
    fontSize: ['18px', '20px'],
    color: '#fff',
    lineHeight: '1.3',
    mb: '0',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textAlign: 'left',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: ['center', 'center', 'flex-start'],
    mt: ['35px', '45px'],
  },
};
