import React from 'react';
import PartnerSection from 'containers/SyncStage/PartnerBroadcast';
import BenefitsSection from '../containers/SyncStage/BroadcastBenefits';
import { handleNewsletter } from '../common/handlers';
import SignUpSection from '../containers/SyncStage/SignUp';
import Layout from '../components/layout';
import BannerRemoteProductionSection from 'containers/SyncStage/BannerRemoteProduction';
import CaseStudyAiR from 'containers/SyncStage/CaseStudyAiR';

const Broadcast = () => {
  return (
    <Layout>
      <BannerRemoteProductionSection />
      <PartnerSection />
      <BenefitsSection />
      <CaseStudyAiR />
      <SignUpSection handleNewsletter={handleNewsletter} />
    </Layout>
  );
};
export default Broadcast;
