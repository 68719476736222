import styled from 'styled-components';

export const BackgroundContainer = styled.div`
  .background {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    .gradient-layer {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
`;

export const CaseStudyDiscordWrapper = styled.section`
  padding: 0;
  background-repeat: no-repeat;
  background-position: 0px 100px;
  width: 100%;
  height: 100%;
  position: relative;

  .content-layer {
    padding: 23px 23px 0 23px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 50vw;
    height: 100%;
    padding-inline: 6rem;
    min-height: 90vh;

    @media (max-width: 1300px) {
      width: 100%;
    }

    @media (max-width: 1100px) {
      padding-inline: 2rem;
      min-height: 80vh;
    }
  }
`;

export const styles = {
  title: {
    fontSize: ['40px', '50px', '60px', '70px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '40px', '40px', '40px', '40px'],
    lineHeight: '1.25',
    textAlign: ['center', 'left'],
  },
  description: {
    fontSize: ['20px', '25px'],
    color: '#fff',
    lineHeight: '1.3',
    mb: '0',
    textAlign: ['center', 'left'],
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'flex-start',
    mt: '5rem',
  },
};
