import React from 'react';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import StaticBanner from 'components/Banner/StaticBanner/StaticBanner';
import { Container, styles } from './banner.style';
import PrimaryButton from 'common/components/Button/PrimaryButton/PrimaryButton';
import BannerBG from 'common/assets/image/syncStage/banner-bg-remote-production.png';
import BannerBGSmall from 'common/assets/image/syncStage/banner-bg-remote-production-small.webp';
import LazyMedia from 'common/components/LazyMedia/LazyMedia';
import BannerLeftCenterText from 'components/Banner/BannerLeftCenterText/BannerLeftCenterText';

const BannerRemoteProductionSection = () => {
  const { title, description, buttonWrapper } = styles;
  return (
    <StaticBanner
      bg={
        <Container>
          <div className="section-idle">
            <LazyMedia src={BannerBG} loadingSrc={BannerBGSmall} alt="SyncStage" />
          </div>
        </Container>
      }
    >
      <BannerLeftCenterText style={{ paddingTop: 'calc(50vh - 60px);' }}>
        <Heading {...title}>
          <React.Fragment>
            CREATE THE <span className="emphasize">CONTROL</span>
            <br />
            <span className="emphasize">ROOM</span> OF THE FUTURE
          </React.Fragment>
        </Heading>
        <Text {...description}>
          <div style={{ maxWidth: '540px' }}>
            Elevate live broadcasts synchronizing remote production teams and expanding possibilities for on-air talent,
            wherever they are in the world.
          </div>
        </Text>
        <br />
        <br />
        <Box {...buttonWrapper}>
          <PrimaryButton title="SIGN UP" onClick={() => window.open(process.env.REQUEST_DEMO_URL, '_blank')} />
        </Box>
      </BannerLeftCenterText>
    </StaticBanner>
  );
};
export default BannerRemoteProductionSection;
