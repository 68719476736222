import React from 'react';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';

import BenefitsSectionWrapper, { BenefitImg, styles } from './benefits.style';
import BuiltForSpeedImg from 'common/assets/image/syncStage/syncstage-built-for-speed.png';
import BuiltForAudioQualityImg from 'common/assets/image/syncStage/syncstage-built-for-audio-quality.png';
import BuiltForSecurity from 'common/assets/image/syncStage/syncstage-built-for-security.png';
import BuiltForInteroperability from 'common/assets/image/syncStage/syncstage-built-for-interoperability.png';

import Fade from 'react-reveal/Fade';

const BenefitsSection = () => {
  const { title, textStyle, titleStyle } = styles;

  return (
    <BenefitsSectionWrapper id="info_section">
      <Box>
        <Heading {...title} content="The benefits" />
      </Box>
      <div className="first-bg"></div>
      <div className="benefits">
        <br />
        <Fade bottom>
          <div className="benefit">
            <BenefitImg src={BuiltForSpeedImg} alt="speed" />
            <Box className="text-box">
              <Heading {...titleStyle}>
                BUILT FOR <span className="emphasize">SPEED</span>
              </Heading>

              <Text {...textStyle}>
                When milliseconds matter, SyncStage has been designed to be the quickest connection between remote teams
                on the planet. We are 5x to 7x faster than other voice/audio over IP communications platforms, allowing
                you to communicate faster between a remote control room team, production crew on site and talent so you
                never miss a moment.
              </Text>
            </Box>
          </div>
        </Fade>
        <Fade bottom>
          <div className="benefit">
            <BenefitImg src={BuiltForAudioQualityImg} alt="quality" />
            <Box className="text-box">
              <Heading {...titleStyle}>
                BUILT FOR <span className="emphasize">QUALITY</span>
              </Heading>
              <Text {...textStyle}>
                Delivers 2x better quality than other voice/audio over IP communications, so you never mishear a
                critical moment.
              </Text>
            </Box>
          </div>
        </Fade>
        <Fade bottom>
          <div className="benefit">
            <BenefitImg src={BuiltForSecurity} alt="daw" />
            <Box className="text-box">
              <Heading {...titleStyle}>
                BUILT FOR <span className="emphasize">SECURITY</span>
              </Heading>
              <Text {...textStyle}>
                Multi layer security and configuration management allowing you securely authenticate discrete streams,
                and separate departments into different channels, at the device level.
              </Text>
            </Box>
          </div>
        </Fade>
        <Fade bottom>
          <div className="benefit">
            <BenefitImg src={BuiltForInteroperability} alt="interoperability" />
            <Box className="text-box">
              <Heading {...titleStyle}>
                BUILT FOR <span className="emphasize">INTEROPERABILITY</span>
              </Heading>
              <Text {...textStyle}>
                Allows you to run remote sessions with production teams across iOS, MacOS, Android, Windows. For a
                complete list of compatible platforms, click{' '}
                <a
                  href="https://docs.sync-stage.com/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: 'rgba(54, 219, 253, 1)', textDecoration: 'none' }}
                >
                  here.
                </a>
              </Text>
            </Box>
          </div>
        </Fade>
      </div>
    </BenefitsSectionWrapper>
  );
};

export default BenefitsSection;
