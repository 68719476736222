import React from 'react';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import { CaseStudyDiscordWrapper, BackgroundContainer, styles } from './caseStudyAiR.style';
import RingBg from 'common/assets/image/syncStage/banner-bg-ring.png';
import RingBgSmall from 'common/assets/image/syncStage/banner-bg-ring-small.webp';
import PrimaryButton from 'common/components/Button/PrimaryButton/PrimaryButton';
import LazyMedia from 'common/components/LazyMedia/LazyMedia';

const CaseStudyAiR = () => {
  const { title, description, buttonWrapper } = styles;
  return (
    <CaseStudyDiscordWrapper id="case_study_discord_section">
      <BackgroundContainer>
        <div className="background">
          <LazyMedia src={RingBg} loadingSrc={RingBgSmall} alt="AiR case study" />
        </div>
        <div className="content-layer">
          <Heading {...title}>
            <React.Fragment>CASE STUDY</React.Fragment>
          </Heading>
          <Text {...description}>
            <React.Fragment>
              SyncStage has been integrated into Advanced Image Robotics (AIR) AIRcloud software as the audio pipeline
              synchronizing comms between director, tech director, producers and remote camera operators. The AIR
              product suite enables a production team to set up robotic cameras and allow remote operators to control
              all aspects from anywhere in the world. The product was named Product of the Year at NAB 2023.
            </React.Fragment>
          </Text>
          <Box {...buttonWrapper}>
            <PrimaryButton
              title="LEARN MORE"
              onClick={() => window.open('https://advancedimagerobotics.com/', '_blank')}
            />
          </Box>
        </div>
      </BackgroundContainer>
    </CaseStudyDiscordWrapper>
  );
};

export default CaseStudyAiR;
